<template>
  <div class="aiLanding">
    <div class="_home"></div>
    <div class="aiLanding_cen">
      <div class="title">
        <img style="margin-right: 5px" src="../../assets/images/ai/logo.png" alt=""><img src="../../assets/images/ai/title.png" alt="">
      </div>
      <div class="content">
        <ul class="content_cen">
          <li @click="jump('/trademarkRejectionAI')" style="margin-right: 40px" class="content_li">
            <img style="height: 629px" src="../../assets/images/ai/Group 221.png" alt="">
            <div @click="jump('/trademarkRejectionAI')" class="btn">
              去试用
            </div>
          </li>
          <li @click="jump('/patentOA')" style="margin-right: 40px" class="content_li">
            <img src="../../assets/images/ai/Group 222.png" alt="">
            <div @click="jump('/patentOA')" class="btn">
              去试用
            </div>
          </li>
          <li @click="jump('/oaApplication')" class="content_li">
            <img src="../../assets/images/ai/Group 223.png" alt="">
            <div @click="jump('/oaApplication')" class="btn">
              去试用
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "index",
        data() {
        return {

        }
      },
        mounted() {
        },
        methods: {
            jump(val) {
              window.location.href = window.location.origin + '/#' + val
              return;
              this.$router.push({
                path: val
              }).then(data => {
                console.log(data,"路由跳转成功信息")
              }).catch(err => {
                console.log(err,"路由跳转错误信息")
              })
          }
        }
    }
</script>

<style lang="less" scoped>
    .aiLanding {
      ._home {
        width: 100%;
        height: 90px;
      }
      .aiLanding_cen {
        overflow-x: hidden;
        width: 100%;
        min-height: calc(100vh - 90px);;
        background: url("../../assets/images/ai/Group 218.png") no-repeat;
        background-size: 100% 100%;
        .title {
          img {
            height: 42px;
          }
          margin-top: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 36px;
          letter-spacing: 2px;
          background: linear-gradient(0.28733818684127654deg, #2965FB 0%, #2E4C99 100%);
          -webkit-background-clip: text;
          color: transparent; /* 设置文字颜色为透明 */
        }
        .content {
          margin: 0 auto;
          margin-bottom: 20px;
          width: 1463px;
          display: flex;
          justify-content: center;
          .content_cen {
            display: flex;
            align-items: flex-end;
            margin-top: 15px;
            margin: 0 auto;
            .content_li {
              width: 393px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .btn {
                margin-top: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 138px;
                height: 38px;
                border-radius: 8px 8px 8px 8px;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                cursor: pointer;
                &:hover {
                  background: #143079!important;
                  box-shadow: 0px 4px 4px 1px rgba(0,0,0,0.28);
                }
              }
              &:nth-of-type(1) {
                .btn {
                  background: linear-gradient( 252deg, #5197F7 0%, #1A4DD3 100%);
                }
              }
              &:nth-of-type(2) {
                .btn {
                  background: linear-gradient( 252deg, #8187EC 0%, #5B58BF 100%);
                }
              }
              &:nth-of-type(3) {
                .btn {
                  background: linear-gradient( 252deg, #5197F7 0%, #224090 100%);
                }
              }
            }

          }
        }
        .content_ {
          margin: 0 auto;
          width: 1463px;
          display: flex;
          justify-content: center;
          li {
            width: 339px;
            display: flex;
            justify-content: center;
            .btn {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 138px;
              height: 38px;
              border-radius: 8px 8px 8px 8px;
              font-weight: 500;
              font-size: 16px;
              color: #FFFFFF;
              cursor: pointer;
            }
            &:nth-of-type(1) {
              margin-right: 40px;
              .btn {
                background: linear-gradient( 252deg, #5197F7 0%, #1A4DD3 100%);
              }
            }
            &:nth-of-type(2) {
              margin-right: 40px;
              .btn {
                background: linear-gradient( 252deg, #8187EC 0%, #5B58BF 100%);
              }
            }
            &:nth-of-type(3) {
              .btn {
                background: linear-gradient( 252deg, #5197F7 0%, #224090 100%);
              }
            }
          }
        }
      }
    }
</style>
